import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "capitalization"
    }}>{`Capitalization`}</h1>
    <h2 {...{
      "id": "sentence-case"
    }}>{`Sentence Case`}</h2>
    <p>{`In sentence case, only the first letter of the first word in the sentence or phrase is capitalized.`}</p>
    <p>{`Use sentence case for instructional text, error message body text, dialog body text, and most other
text that is grammatically a full sentence. If you have questions about whether something should be
in sentence case, reach out on #ux-product-content.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Enter your name and address below.`}</li>
      </ul>
    </Do>
    <p>{`Include periods at the ends of full sentences in UI text; for example, in validations or error
messages.`}</p>
    <h2 {...{
      "id": "title-case"
    }}>{`Title Case`}</h2>
    <p>{`In title case, each word in the phrase is capitalized, except for articles (a, an, the) and most
prepositions (in, on, for, etc).`}</p>
    <p>{`Generally, if something is a title, header, or subheader, it should be in title case. Use title case
for page headers, column headers, table or grid headers, and for button text.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Skills and Experience`}</li>
        <li parentName="ul">{`Connect to Supplier Website`}</li>
      </ul>
    </Do>
    <h2 {...{
      "id": "writing-about-tasks"
    }}>{`Writing About Tasks`}</h2>
    <p>{`When referencing a specific task, use title case. However, when you are referencing the actions of a
task more generally in running text, use sentence case.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`When you create your plan structure...`}</li>
        <li parentName="ul">{`The `}<strong parentName="li">{`Create Plan Structure`}</strong>{` task enables...`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      